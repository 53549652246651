import React from "react";
import classnames from "classnames";
import { Icon } from "@jobber/components/dist/Icon";
import { LoginStates, useUserContext } from "@/context/user";
import * as styles from "./MobileSideNavItems.module.css";

interface MobileSideNavItemsProps {
  focusedItem: number;
  subNavsState: Record<string, SubNav>;
  mobileSideNavItems: SideNavItem[];
  handleItemClicked: (item: SideNavItem) => () => void;
}

export function MobileSideNavItems({
  focusedItem,
  subNavsState,
  mobileSideNavItems,
  handleItemClicked,
}: MobileSideNavItemsProps) {
  const { state } = useUserContext();
  const isLoggedIn =
    state === LoginStates.Loading ? false : state === LoginStates.LoggedIn;

  return (
    <div className={styles.navItemsContainer}>
      {mobileSideNavItems
        .filter(sideNavItem => !sideNavItem.requireSession || isLoggedIn)
        .map(item => {
          let classNames = [styles.navItem];

          if (item.id === focusedItem) {
            classNames = [...classNames, styles.navItemFocus];
          }

          if (item.footer) {
            classNames = [...classNames, styles.navFooter];
          }

          if (item.subItems) {
            classNames = [...classNames, styles.navSubItemHeader];
          }

          if (item.subItems && subNavsState[item.id]?.subItemFocused) {
            if (subNavsState[item.id]?.open) {
              classNames = [...classNames, styles.navSubItemHeaderOpen];
            } else {
              classNames = [...classNames, styles.navItemFocus];
            }
          }

          return (
            <div key={item.id} className={item.footer ? styles.navFooter : ""}>
              <div
                className={classnames(classNames)}
                onClick={handleItemClicked(item)}
              >
                {item.title}
                {item.subItems && (
                  <Icon
                    name={subNavsState[item.id]?.open ? "minus2" : "plus2"}
                  />
                )}
              </div>
              {subNavsState[item.id]?.open && item.subItems && (
                <>
                  <MobileNavSubItems
                    parentItem={item}
                    subItems={item.subItems}
                    focusedItem={focusedItem}
                    subNavsState={subNavsState}
                    handleItemClicked={handleItemClicked}
                  />
                </>
              )}
            </div>
          );
        })}
    </div>
  );
}

interface MobileNavSubItemsProps {
  parentItem: SideNavItem;
  subItems: SideNavItem[];
  focusedItem: number;
  subNavsState: Record<string, SubNav>;
  handleItemClicked: (item: SideNavItem) => () => void;
}

function MobileNavSubItems({
  parentItem,
  subItems,
  focusedItem,
  subNavsState,
  handleItemClicked,
}: MobileNavSubItemsProps) {
  const renderedSubItems = subItems.map(item => {
    let classNames = [styles.navItem];
    if (item.id === focusedItem) {
      classNames = [...classNames, styles.navItemFocus];
    }

    if (item.subItems && subNavsState[item.id]?.subItemFocused) {
      if (subNavsState[item.id]?.open) {
        classNames = [...classNames, styles.navSubItemHeaderOpen];
      } else {
        classNames = [...classNames, styles.navItemFocus];
      }
    }

    return (
      <div key={item.id}>
        <div
          className={classnames(classNames)}
          onClick={handleItemClicked(item)}
        >
          {item.title}
          {item.subItems && (
            <Icon
              name={subNavsState[item.id]?.open ? "arrowUp" : "arrowDown"}
            />
          )}
        </div>
        {subNavsState[item.id]?.open && item.subItems && (
          <MobileNavSubItems
            parentItem={item}
            subItems={item.subItems}
            focusedItem={focusedItem}
            subNavsState={subNavsState}
            handleItemClicked={handleItemClicked}
          />
        )}
      </div>
    );
  });

  return (
    <div
      data-testid={`subItemGroup-${parentItem.id}`}
      className={determineSubGroupClasses(parentItem)}
    >
      {renderedSubItems}
    </div>
  );
}

export interface SubNav {
  open: boolean;
  subItemFocused: boolean;
  title: string;
  link?: string;
}

export interface SideNavItem {
  id: number;
  title: string;
  link?: string;
  level: number;
  footer?: boolean;
  requireSession?: boolean;
  subItems?: SideNavItem[];
  externalLink?: boolean;
}

function determineSubGroupClasses(parentItem: SideNavItem): string | undefined {
  switch (parentItem.level) {
    case 0: {
      return styles.navFirstSubItemGroup;
    }
    case 1: {
      return styles.navSubItemGroup;
    }
    default: {
      return styles.navSubItemGroup;
    }
  }
}
