import React from "react";
import classnames from "classnames";
import { Button } from "@jobber/components/Button";
import { navigate } from "gatsby";
import { useAmplitude } from "@/hooks/useAmplitude";
import { safelyGetLocationPathname } from "@/utils/location";
import * as styles from "./NavigationButtons.module.css";
import { NavigationLink } from "../NavigationLink";

interface NavigationButtonsProps {
  mobileView: boolean;
  links: NavigationLink[];
}

export function NavigationButtons({
  mobileView,
  links = [],
}: NavigationButtonsProps) {
  const wrapper = classnames(styles.links, { [styles.fullwidth]: !mobileView });

  return (
    <div className={wrapper}>
      {links.map(link => (
        <RenderNavigationLink
          key={link.label}
          link={link}
          mobileView={mobileView}
        />
      ))}
    </div>
  );
}

interface NavigationLinkProps {
  link: NavigationLink;
  mobileView: boolean;
}

function RenderNavigationLink({
  link: { label, path, external },
  mobileView,
}: NavigationLinkProps) {
  const { logEvent } = useAmplitude();
  let linkWrapperStyles = [styles.linksWrapper];

  const activePath = safelyGetLocationPathname();
  const isLinkActive = activePath.replace(/\/$/, "") === path;

  if (isLinkActive) {
    linkWrapperStyles = [...linkWrapperStyles, styles.linkActive];
  }

  return (
    <div className={classnames(linkWrapperStyles)}>
      <Button
        label={label}
        type="tertiary"
        fullWidth={!mobileView}
        variation="subtle"
        onClick={handleClick}
        external={external}
        {...(external && { url: path })}
      />
    </div>
  );

  function handleClick() {
    logEvent({
      name: "Clicked Navigation",
      properties: { value: path },
    });
    if (!external) navigate(path);
  }
}
