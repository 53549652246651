import React, { useState } from "react";
import { navigate } from "gatsby";
import { Icon } from "@jobber/components/dist/Icon";
import { safelyGetLocationPathname } from "@/utils/location";
import * as styles from "./DocsSidebar.module.css";

export interface Item {
  title: string;
  link?: string;
  id: number;
  footer?: boolean;
  subItems?:
    | {
        title: string;
        link?: string;
        id: number;
      }[]
    | undefined;
}

interface SubNav {
  open: boolean;
  subItemFocused: boolean;
  title: string;
  link?: string;
}

export function DocsSidebar({ items }: { items: Item[] }) {
  const subNavs = determineSubNavs();

  const itemFocused = determineFocusedItem();
  const [subNavsState, setSubNavsState] = useState(subNavs);

  return <div className={styles.navBar}>{RenderItems()}</div>;

  function RenderItems() {
    return items.map(item => {
      let classNames = [styles.navItem];
      if (item.id === itemFocused) {
        classNames = [...classNames, styles.navItemFocus];
      }

      if (item.footer) {
        classNames = [...classNames, styles.navFooter];
      }

      if (item.subItems) {
        classNames = [...classNames, styles.navSubItemHeader];
      }

      if (item.subItems && subNavsState[item.id]?.subItemFocused) {
        if (subNavsState[item.id]?.open) {
          classNames = [...classNames, styles.navSubItemHeaderOpen];
        } else {
          classNames = [...classNames, styles.navItemFocus];
        }
      }

      return (
        <div key={item.id} className={item.footer ? styles.navFooter : ""}>
          <div
            className={combineClassNames(classNames)}
            onClick={handleOnClick(item)}
          >
            {item.title}
            {item.subItems && (
              <Icon
                name={subNavsState[item.id]?.open ? "arrowUp" : "arrowDown"}
              />
            )}
          </div>
          {subNavsState[item.id]?.open && item.subItems && (
            <div
              data-testid={`subItemGroup-${item.id}`}
              className={styles.navSubItemGroup}
            >
              {RenderSubItems(item.subItems)}
            </div>
          )}
        </div>
      );
    });
  }

  function RenderSubItems(
    subItems: { title: string; link?: string; id: number }[],
  ) {
    return subItems.map(item => {
      let classNames = [styles.navItem, styles.navSubItem];
      if (item.id === itemFocused) {
        classNames = [...classNames, styles.navItemFocus];
      }
      return (
        <div
          className={combineClassNames(classNames)}
          onClick={() => {
            if (item.link) {
              navigate(item.link);
            }
          }}
          key={item.id}
        >
          {item.title}
        </div>
      );
    });
  }

  function determineFocusedItem() {
    let focusedItem = items[0].id;

    items.forEach(item => {
      if (item.subItems) {
        item.subItems.forEach(subItem => {
          if (
            subItem?.link === safelyGetLocationPathname() ||
            subItem?.link?.replace(/\/$/, "") ===
              safelyGetLocationPathname().replace(/\/$/, "")
          ) {
            focusedItem = subItem.id;

            if (subNavs[item.id]) {
              subNavs[item.id].open = true;
              subNavs[item.id].subItemFocused = true;
            }
          }
        });
      } else {
        if (
          item.link === safelyGetLocationPathname() ||
          item.link?.replace(/\/$/, "") ===
            safelyGetLocationPathname().replace(/\/$/, "")
        ) {
          focusedItem = item.id;
        }
      }
    });

    return focusedItem;
  }

  function determineSubNavs(): Record<string, SubNav> {
    const newSubNavs: Record<string, SubNav> = {};

    items.forEach(item => {
      if (item.subItems) {
        newSubNavs[item.id] = {
          open: false,
          subItemFocused: false,
          title: item.title,
          link: item.link,
        };
      }
    });

    return newSubNavs;
  }

  function handleOnClick(
    item: Item,
  ): React.MouseEventHandler<HTMLDivElement> | undefined {
    if (item?.subItems) {
      return () => {
        const newSubNavs = { ...subNavsState };
        if (newSubNavs[item.id]) {
          newSubNavs[item.id].open = !newSubNavs[item.id].open;

          setSubNavsState(newSubNavs);
        }
      };
    } else {
      return () => navigate(item.link ? item.link : "");
    }
  }

  function combineClassNames(classNames: string[]): string {
    return classNames.join(" ");
  }
}
