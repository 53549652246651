import React from "react";
import classnames from "classnames";
import { Link } from "gatsby";
import { ButtonDismiss } from "@jobber/components/ButtonDismiss";
import { Logo } from "@/components/Svg/Logo";
import * as styles from "./MobileSideNav.module.css";
import { MobileSideNavContents } from "./components";

interface MobileSideNavProps {
  toggleState: boolean;
  toggleSideNav(): void;
  handleLogoClick(): void;
}

export function MobileSideNav({
  toggleState,
  toggleSideNav,
  handleLogoClick,
}: MobileSideNavProps) {
  const sideNavOpenCSS = toggleState ? styles.open : "";

  return (
    <>
      <div className={classnames([styles.sideNavWrapper, sideNavOpenCSS])}>
        <div className={styles.sideNavHeader}>
          <div className={styles.sideNavLogo}>
            <Link
              to="/apps"
              aria-label="Developer Center"
              onClick={handleLogoClick}
            >
              <Logo />
            </Link>
          </div>
          <div className={styles.sideNavDismiss}>
            <ButtonDismiss
              ariaLabel="close navigation"
              onClick={toggleSideNav}
            />
          </div>
        </div>
        <div className={styles.sideNav}>
          <MobileSideNavContents toggleSideNav={toggleSideNav} />
        </div>
      </div>
      {toggleState && (
        <div className={styles.opacityBackground} onClick={toggleSideNav} />
      )}
    </>
  );
}
