import React, { useState } from "react";
import { Link } from "gatsby";
import { useResizeObserver } from "@jobber/hooks";
import { Button } from "@jobber/components/Button";
import { useAmplitude } from "@/hooks/useAmplitude";
import { Logo } from "@/components/Svg/Logo";
import * as styles from "./Navigation.module.css";
import { NavigationLink } from "./NavigationLink";
import { NavigationButtons } from "./NavigationButtons";
import { SessionButtons } from "./SessionButtons";
import { MobileSideNav } from "./MobileSideNav";

interface NavigationProps {
  links: NavigationLink[];
}

export function Navigation({ links = [] }: NavigationProps) {
  const { logEvent } = useAmplitude();
  const [ref, { exactWidth }] = useResizeObserver();
  const mobileView = exactWidth < 1024;

  const [displaySideNav, setSideNav] = useState(false);
  return (
    <>
      <div />
      <div ref={ref} className={styles.wrapper}>
        <nav className={styles.navigation}>
          <div className={styles.showOnlyOnDesktop}>
            <div className={styles.sideNavLogo}>
              <Link
                to="/apps"
                aria-label="Developer Center"
                onClick={handleLogoClick}
              >
                <Logo />
              </Link>
            </div>
          </div>
          <div className={styles.showOnlyOnMobile}>
            <div className={styles.menu}>
              <Button
                type="tertiary"
                icon="menu"
                variation="subtle"
                ariaLabel="close"
                onClick={toggleSideNav}
              />
            </div>
            <MobileSideNav
              toggleState={displaySideNav}
              toggleSideNav={toggleSideNav}
              handleLogoClick={handleLogoClick}
            />
          </div>
          <div className={styles.buttons}>
            <div className={styles.docLinks}>
              <NavigationButtons mobileView={mobileView} links={links} />
            </div>
            <div className={styles.loginSignupButtons}>
              <SessionButtons mobileView={mobileView} />
            </div>
          </div>
        </nav>
      </div>
    </>
  );

  function toggleSideNav(): void {
    setSideNav(!displaySideNav);
  }

  function handleLogoClick() {
    logEvent({
      name: "Clicked Navigation",
      properties: { value: "/apps" },
    });
  }
}
