import React from "react";
import { navigate } from "gatsby";
import { Auth } from "@aws-amplify/auth";
import { useUserContext } from "@/context/user";
import { safelyGetLocationPathname } from "@/utils/location";
import { SessionButtons } from "./SessionButtons";

interface SessionButtonsLoaderProps {
  mobileView: boolean;
}

export function SessionButtonsLoader({
  mobileView,
}: SessionButtonsLoaderProps) {
  const { state } = useUserContext();
  return (
    <SessionButtons
      mobileView={mobileView}
      state={state}
      onViewSettingsRequest={handleViewSettingsRequest}
      onLogoutRequest={handleLogoutRequest}
      onLoginRequest={handleLoginRequest}
    />
  );

  function handleViewSettingsRequest() {
    navigate("/settings");
  }

  function handleLogoutRequest() {
    Auth.signOut();
  }

  function handleLoginRequest() {
    const from = safelyGetLocationPathname();
    navigate("/login", {
      state: { from },
    });
  }
}
