import React from "react";
import classnames from "classnames";
import { Navigation } from "@/components/Navigation";
import * as styles from "./PresessionPage.module.css";
import { PageProps } from "../PageProps";

export function PresessionPage(props: PageProps) {
  const { children, fullWidth = false } = props;

  const content = classnames([styles.content], {
    [styles.contentFullWidth]: fullWidth,
  });

  return (
    <>
      <Navigation />
      <div className={styles.page}>
        <div className={styles.column}>
          <div className={content}>{children}</div>
        </div>
      </div>
    </>
  );
}
