import React from "react";
import classnames from "classnames";
import { Page } from "@jobber/components/Page";
import { Navigation } from "@/components/Navigation";
import * as styles from "./BasePage.module.css";
import { PageProps } from "../PageProps";

export function BasePage(props: PageProps) {
  const { title, children, fullwidth } = props;
  const content = classnames([styles.content], {
    [styles.centeredWithMaxWidth]: !fullwidth,
  });

  return (
    <>
      <Navigation />
      <div className={content}>
        {title && children ? <Page {...props} title={title} /> : children}
      </div>
    </>
  );
}
