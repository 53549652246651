import React from "react";
import { Button } from "@jobber/components/Button";
import { Menu } from "@jobber/components/Menu";
import { Spinner } from "@jobber/components/Spinner";
import { LoginStates } from "@/context/user";
import * as styles from "./SessionButtons.module.css";

interface SessionButtonsProps {
  mobileView: boolean;
  state: LoginStates;
  onViewSettingsRequest(): void;
  onLogoutRequest(): void;
  onLoginRequest(): void;
}

export function SessionButtons({
  mobileView,
  state,
  onViewSettingsRequest,
  onLogoutRequest,
  onLoginRequest,
}: SessionButtonsProps) {
  if (state == LoginStates.Loading) {
    return (
      <div className={styles.spinner}>
        <Spinner size="small" />
      </div>
    );
  }

  const buttonSize = mobileView ? "small" : "base";

  if (state == LoginStates.LoggedIn) {
    return (
      <Menu
        activator={
          <div className={styles.accountMenu}>
            <Button
              icon="user"
              ariaLabel="Account"
              label={!mobileView ? "Account" : undefined}
              fullWidth={!mobileView}
              type="tertiary"
              variation="subtle"
            />
          </div>
        }
        items={[
          {
            actions: [
              {
                label: "Settings",
                icon: "cog",
                onClick: onViewSettingsRequest,
              },
              { label: "Logout", icon: "logout", onClick: onLogoutRequest },
            ],
          },
        ]}
      />
    );
  }

  return (
    <>
      <div className={styles.loginSignupButtons}>
        <Button
          onClick={onLoginRequest}
          label="Login"
          type="primary"
          size={buttonSize}
        />
        <div className={styles.signUpButton}>
          <Button
            url="/signup"
            label="Sign Up"
            type="secondary"
            size={buttonSize}
          />
        </div>
      </div>
    </>
  );
}
