import React, { useEffect } from "react";
import "@jobber/design/foundation.css";
import "@jobber/fonts";
import { Amplify } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import { ErrorBoundary, Provider } from "@rollbar/react";
import { BannerProvider } from "@/context/banner";
import { UserProvider } from "@/context/user";
import { ApolloProviderWrapper } from "@/utils/ApolloProviderWrapper";
import { useAmplitude } from "@/hooks/useAmplitude";
import { rollbarConfig } from "@/utils/rollbar";
import awsconfig from "../aws-exports";
import "./wrapRootElement.css";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

export const wrapRootElement = ({ element }: any) => {
  const WrappedRootElement = () => {
    const { initialize } = useAmplitude();
    useEffect(() => {
      initialize();
    }, []);
    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <UserProvider>
            <ApolloProviderWrapper>
              <BannerProvider>{element}</BannerProvider>
            </ApolloProviderWrapper>
          </UserProvider>
        </ErrorBoundary>
      </Provider>
    );
  };
  return <WrappedRootElement />;
};
