import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { SEO } from "./SEO";

interface SeoProps {
  title?: string;
  description?: string;
  keywords?: string;
}

export function SEOLoader(props: SeoProps) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          keywords
        }
      }
    }
  `);
  let { title, description, keywords } = data.site.siteMetadata;

  if (typeof props.title !== "undefined") {
    title = `Jobber's Developer Center | ${props.title}`;
  }

  if (typeof props.description !== "undefined") {
    description = props.description;
  }

  if (typeof props.keywords !== "undefined") {
    keywords = keywords.title;
  }

  return <SEO title={title} description={description} keywords={keywords} />;
}
