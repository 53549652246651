import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface HelmetProps {
  title: string;
  description: string;
  keywords: string[];
}

export function SEO({ title, description, keywords }: HelmetProps) {
  return (
    <HelmetProvider>
      <Helmet
        title={title}
        meta={[
          { name: "description", content: description },
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1.0",
          },
          { name: "author", content: "Jobber" },
          { name: "keywords", content: keywords.join(",") },
        ]}
      >
        <link rel="shortcut icon" href="/favicon.png" />
      </Helmet>
    </HelmetProvider>
  );
}
