import { SideNavItem } from "./mobileSideNavItems";

export const docsLinks = (): SideNavItem[] => {
  let offsetId = 1000;
  return [
    {
      id: offsetId++,
      level: 1,
      title: "Developer Center",
      link: "/docs/",
    },
    {
      id: offsetId++,
      level: 1,
      title: "Getting Started",
      link: "/docs/getting_started/",
    },
    {
      id: offsetId++,
      level: 1,
      title: "Using Jobber’s API",
      subItems: [
        {
          id: offsetId++,
          level: 2,
          title: "API Queries and Mutations",
          link: "/docs/using_jobbers_api/api_queries_and_mutations/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "Setting up Webhooks",
          link: "/docs/using_jobbers_api/setting_up_webhooks/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "App-Configured Custom Fields",
          link: "/docs/using_jobbers_api/custom_fields/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "API Versioning",
          link: "/docs/using_jobbers_api/api_versioning/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "API Rate Limits",
          link: "/docs/using_jobbers_api/api_rate_limits/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "Handling API Errors",
          link: "/docs/using_jobbers_api/handling_api_errors/",
        },
      ],
    },
    {
      id: offsetId++,
      level: 1,
      title: "Building Your App",
      subItems: [
        {
          id: offsetId++,
          level: 2,
          title: "Approved Categories of Apps",
          link: "/docs/building_your_app/approved_categories_of_apps/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "App Lifecycle",
          link: "/docs/building_your_app/app_lifecycle/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "App Authorization (OAuth 2.0)",
          link: "/docs/building_your_app/app_authorization/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "Refresh Token Rotation",
          link: "/docs/building_your_app/refresh_token_rotation/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "Editing Your App",
          link: "/docs/building_your_app/editing_your_app/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "Testing Your App",
          link: "/docs/building_your_app/testing_your_app/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "Atlantis Design System",
          link: "/docs/building_your_app/atlantis_design_system/",
        },
      ],
    },
    {
      id: offsetId++,
      level: 1,
      title: "Publishing Your App",
      subItems: [
        {
          id: offsetId++,
          level: 2,
          title: "App Listing Details",
          link: "/docs/publishing_your_app/app_listing_details/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "App Review Process",
          link: "/docs/publishing_your_app/app_review_process/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "Manage App Button",
          link: "/docs/publishing_your_app/manage_app_button/",
        },
        {
          id: offsetId++,
          level: 2,
          title: "Editing a Published App",
          link: "/docs/publishing_your_app/editing_a_published_app/",
        },
      ],
    },
    {
      id: offsetId++,
      level: 1,
      title: "App Template Project",
      link: "/docs/app_template_project/",
    },
    {
      id: offsetId++,
      level: 1,
      title: "Custom Integrations",
      link: "/docs/custom_integrations/",
    },
  ];
};

let docsSideNavId = 0;
export const docsSideNavItemData: SideNavItem[] = [
  ...docsLinks(),
  {
    id: docsSideNavId++,
    level: 1,
    title: "Changelog",
    link: "/docs/changelog/",
  },
  {
    id: docsSideNavId++,
    level: 1,
    title: "Terms of Service",
    link: "/docs/legal/terms",
    footer: true,
  },
];

let mobileSideNavId = 0;
export const mobileSideNavItemData: SideNavItem[] = [
  {
    id: mobileSideNavId++,
    level: 0,
    title: "Manage Apps",
    link: "/apps/",
    requireSession: true,
  },
  {
    id: mobileSideNavId++,
    level: 0,
    title: "Docs",
    subItems: docsLinks(),
  },
  {
    id: mobileSideNavId++,
    level: 0,
    title: "Changelog",
    link: "/docs/changelog/",
  },
  {
    id: mobileSideNavId++,
    level: 0,
    title: "Design System",
    link: "https://atlantis.getjobber.com/",
    externalLink: true,
  },
  {
    id: mobileSideNavId++,
    level: 1,
    title: "Terms of Service",
    link: "/docs/legal/terms",
    footer: true,
  },
];
