import amplitude from "amplitude-js";
import snakecaseKeys from "snakecase-keys";
import { AmplitudeEvent } from "./events/AmplitudeEvent";

/**
 * Log an event to Amplitude
 * @param event The event to be logged
 */
export function logEvent(event: AmplitudeEvent) {
  if (typeof window == "undefined") return; // Gatsby build proccess will throw an error as window is undefined, which is required by amplitude.
  const { name, properties } = event;
  let defaultProperties = properties;
  defaultProperties = {
    app: "Developer Center",
    url: window.location.href,
    ...defaultProperties,
  };
  const snakedProperties = snakecaseKeys(defaultProperties);
  amplitude.getInstance().logEvent(name, snakedProperties);
}
