import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { cloneDeep, isEqual } from "lodash";
import { safelyGetLocationPathname } from "@/utils/location";
import { mobileSideNavItemData } from "./sideNavItemData";
import { MobileSideNavItems, SideNavItem, SubNav } from "./mobileSideNavItems";

interface MobileSideNavContentsProps {
  toggleSideNav(): void;
}

export function MobileSideNavContents({
  toggleSideNav,
}: MobileSideNavContentsProps) {
  const subNavs = determineSubNavs(mobileSideNavItemData);

  const [subNavsState, setSubNavsState] = useState(subNavs);
  let focusedItem = determineFocusedItem(
    mobileSideNavItemData,
    subNavsState,
    setSubNavsState,
  );

  useEffect(() => {
    focusedItem = determineFocusedItem(
      mobileSideNavItemData,
      subNavsState,
      setSubNavsState,
      true,
    );
  }, []);

  function handleItemClicked(item: SideNavItem): () => void {
    if (item?.subItems) {
      return () => {
        const newSubNavs = { ...subNavsState };
        if (newSubNavs[item.id]) {
          newSubNavs[item.id].open = !newSubNavs[item.id].open;

          setSubNavsState(newSubNavs);
        }
      };
    } else {
      if (item.externalLink) {
        return () => {
          window.open(item.link ? item.link : "", "_blank");
          toggleSideNav();
        };
      } else {
        return () => {
          navigate(item.link ? item.link : "");
          toggleSideNav();
        };
      }
    }
  }

  return (
    <MobileSideNavItems
      focusedItem={focusedItem}
      subNavsState={subNavsState}
      mobileSideNavItems={mobileSideNavItemData}
      handleItemClicked={handleItemClicked}
    />
  );
}

export function determineFocusedItem(
  items: SideNavItem[],
  subNavsState: Record<string, SubNav>,
  setSubNavsState: React.Dispatch<React.SetStateAction<Record<string, SubNav>>>,
  firstRender = false,
) {
  let focusedItem = items[0].id;
  const newSubNavState = cloneDeep(subNavsState);

  Object.keys(newSubNavState).forEach(key => {
    newSubNavState[key].subItemFocused = false;
  });

  const findFocusedItemInNavItems = (
    navItems: SideNavItem[],
    subNavItems: SideNavItem[],
  ) => {
    navItems.forEach(navItem => {
      if (navItem.subItems) {
        findFocusedItemInNavItems(navItem.subItems, [...subNavItems, navItem]);
      } else {
        if (
          navItem.link === safelyGetLocationPathname() ||
          navItem.link?.replace(/\/$/, "") ===
            safelyGetLocationPathname().replace(/\/$/, "")
        ) {
          focusedItem = navItem.id;

          subNavItems.forEach(subNavItem => {
            if (newSubNavState[subNavItem.id]) {
              newSubNavState[subNavItem.id].subItemFocused = true;
              if (firstRender) {
                newSubNavState[subNavItem.id].open = true;
              }
            }
          });
        }
      }
    });
  };

  findFocusedItemInNavItems(items, []);

  if (!isEqual(subNavsState, newSubNavState)) {
    setSubNavsState(newSubNavState);
  }

  return focusedItem;
}

export function determineSubNavs(items: SideNavItem[]): Record<string, SubNav> {
  const newSubNavs: Record<string, SubNav> = {};

  const searchForSubNavs = (navItems: SideNavItem[]) => {
    navItems.forEach(navItem => {
      if (navItem.subItems) {
        newSubNavs[navItem.id] = {
          open: false,
          subItemFocused: false,
          title: navItem.title,
          link: navItem.link,
        };
        searchForSubNavs(navItem.subItems);
      }
    });
  };

  searchForSubNavs(items);

  return newSubNavs;
}
