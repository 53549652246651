import React, { useMemo } from "react";
import { LoginStates, useUserContext } from "@/context/user";
import { Navigation } from "./Navigation";
import { NavigationLink } from "./NavigationLink";

const navigationLinks: NavigationLink[] = [
  {
    label: "Manage Apps",
    path: "/apps",
    authenticated: true,
  },
  {
    label: "Docs",
    path: "/docs",
  },
  {
    label: "Changelog",
    path: "/docs/changelog",
  },
  {
    label: "Design System",
    path: "https://atlantis.getjobber.com",
    external: true,
  },
];

export function NavigationLoader() {
  const { state } = useUserContext();

  const links = useMemo(
    () =>
      navigationLinks
        .filter(
          ({ authenticated }) =>
            !authenticated || (authenticated && state === LoginStates.LoggedIn),
        )
        .map(link => ({
          ...link,
        })),
    [navigationLinks, state],
  );
  return <Navigation links={links} />;
}
