import React, {
  ComponentProps,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Banner, BannerType } from "@jobber/components/Banner";
import { globalHistory } from "@reach/router";

interface ProviderProps {
  children: React.ReactNode;
}

interface ShowBannerProps
  extends Pick<ComponentProps<typeof Banner>, "primaryAction"> {
  message: string;
  variation: BannerType;
  resetOnNavigation?: boolean;
}

interface BannerProviderContextState {
  message?: string;
  type: BannerType;
  visible: boolean;
  viewed: boolean;
  resetOnNavigation: boolean;
  primaryAction?: ShowBannerProps["primaryAction"];
  showBanner: (props: ShowBannerProps) => void;
  hideBanner: () => void;
  viewBanner: () => void;
}

export const BannerStateContext = createContext<
  BannerProviderContextState | undefined
>(undefined);

function BannerProvider({ children }: ProviderProps) {
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [type, setType] = useState<BannerType>("notice");
  const [visible, setVisible] = useState<boolean>(false);
  const [viewed, setViewed] = useState<boolean>(false);
  const [resetOnNavigation, setResetOnNavigation] = useState<boolean>(true);
  const [primaryAction, setPrimaryAction] =
    useState<ShowBannerProps["primaryAction"]>();

  function showBanner(props: ShowBannerProps) {
    if (props.resetOnNavigation !== undefined) {
      setResetOnNavigation(props?.resetOnNavigation);
    }
    setMessage(props.message);
    setType(props.variation);
    setVisible(true);
    setViewed(false);
    setPrimaryAction(props.primaryAction);
  }

  function hideBanner() {
    setVisible(false);
  }

  function viewBanner() {
    setViewed(true);
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action !== "PUSH") return null;

      if (resetOnNavigation === false) {
        setResetOnNavigation(true);
      } else {
        hideBanner();
      }
    });
  }, [visible, resetOnNavigation]);

  const value = {
    message: message,
    type: type,
    visible: visible,
    viewed: viewed,
    resetOnNavigation: resetOnNavigation,
    primaryAction: primaryAction,
    showBanner: showBanner,
    hideBanner: hideBanner,
    viewBanner: viewBanner,
  };

  return (
    <BannerStateContext.Provider value={value}>
      {children}
    </BannerStateContext.Provider>
  );
}

function useBannerContext() {
  const context = useContext(BannerStateContext);

  if (context === undefined) {
    throw new Error("useBannerContext must be used within a BannerProvider");
  }

  return context;
}

export { BannerProvider, useBannerContext };
