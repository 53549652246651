import React from "react";
import classnames from "classnames";
import { Page } from "@jobber/components/Page";
import { DocsSidebar } from "@/features/DocsSidebar";
import { docsSideNavItemData } from "@/components/Navigation/MobileSideNav/components/sideNavItemData";
import * as styles from "./DocPage.module.css";
import { PageProps } from "../PageProps";

export function DocPage(props: PageProps) {
  const { title, children, fullwidth } = props;
  const content = classnames({
    [styles.centeredWithMaxWidth]: !fullwidth,
  });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.sideNavSection}>
          <DocsSidebar items={docsSideNavItemData} />
        </div>
        <div className={styles.markDownSection}>
          <div className={content}>
            {title && children ? <Page {...props} title={title} /> : children}
          </div>
        </div>
      </div>
    </>
  );
}
