import amplitude from "amplitude-js";
import { logEvent } from "@/utils/amplitude";

export function useAmplitude() {
  /**
   * Initializes Amplitude for use within Developer Center
   */
  function initialize() {
    if (typeof window == "undefined") return; // Gatsby build proccess will throw an error as window is undefined, which is required by amplitude.
    const AMPLITUDE_API_KEY = process.env.GATSBY_AMPLITUDE_API_KEY;
    if (typeof AMPLITUDE_API_KEY === "undefined") {
      throw new Error("Amplitude API Key is not defined");
    }
    amplitude.getInstance().init(AMPLITUDE_API_KEY);
  }

  /**
   * Update the user ID, so that events are tracked against that user.
   * @param userId The users ID, if undefined assuming an anonymous/logged out user.
   */
  function setUser(userId: string | undefined) {
    if (typeof window == "undefined") return; // Gatsby build proccess will throw an error as window is undefined, which is required by amplitude.
    if (userId) {
      amplitude.getInstance().setUserId(userId);
      return;
    }

    // Anonymous / Logged out users, see: https://developers.amplitude.com/docs/javascript#log-out-and-anonymous-users
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
  }

  return { initialize, setUser, logEvent };
}
