import React from "react";
import { BasePage } from "./BasePage";
import { SessionPage } from "./SessionPage";
import { PresessionPage } from "./PresessionPage";
import { PageProps } from "./PageProps";
import { DocPage } from "./DocPage";

export function Page(props: PageProps) {
  const { variation = "base" } = props;

  if (variation == "session") {
    return <SessionPage {...props} />;
  } else if (variation == "presession") {
    return <PresessionPage {...props} />;
  } else if (variation == "docs") {
    return <DocPage {...props} />;
  } else {
    return <BasePage {...props} />;
  }
}
