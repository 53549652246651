import React from "react";
import { SEO } from "@/components/SEO";
import { Page } from "@/components/Page";

export const wrapPageElement = ({ element, props }: any) => {
  return <WrapWithLayout element={element} props={props} />;
};

function WrapWithLayout({ element, props: { path } }: any) {
  const isDocumentationPage = path.startsWith("/docs");
  const Wrapper = isDocumentationPage ? Page : React.Fragment;

  return (
    <Wrapper
      {...(isDocumentationPage && { variation: "base", fullwidth: true })}
    >
      <SEO />
      {element}
    </Wrapper>
  );
}
