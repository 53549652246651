import React from "react";
import { Navigation } from "@/components/Navigation/Navigation";
import * as styles from "./SessionPage.module.css";
import { PageProps } from "../PageProps";

export function SessionPage(props: PageProps) {
  const { children } = props;

  return (
    <div className={styles.page}>
      <div className={styles.column}>
        <Navigation />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}
